import cx from 'classnames'
import Link from 'components/Link'
import Overline from 'components/Overline'
import Image from 'components/Image'
import useMedia from 'use-media'
import { screens } from 'lib/constants'
import PressIconModule from './PressIconModule'

export default function Press({ module, settings }) {
  const isScreenExtraLarge = useMedia({ minWidth: screens.xl })
  if (module.useNewLayout) return (<PressIconModule module={module} settings={settings} />);

  const Logo = ({ logo }) => {
    const { width, height, aspectRatio } = logo.svg.asset.metadata.dimensions
    const renderedHeight = isScreenExtraLarge ? 24 : 20
    return (
      <div
        style={{
          width: `${height * aspectRatio}px`,
          height: `${renderedHeight}px`,
        }}
      >
        <Image
          image={logo.svg}
          alt={logo.title}
          placeholder={false}
          fill={true}
          fit="contain"
          width={width}
          height={height}
        />
      </div>
    )
  }

  return (
    <div
      className={cx('relative text-center', {
        'mt-50 l:mt-80': settings.hasMarginTop,
        'mb-25 l:mb-45': settings.hasMarginBottom,
      })}
    >
      <Overline>{module.overline}</Overline>
      <div className="overflow-x-auto hide-scrollbar">
        <ul className="whitespace-nowrap text-0 py-45 px-15 space-x-40 2xs:space-x-35 xl:space-x-45">
          {module.logos.map((logo) => (
            <li className="inline-block last:pr-15" key={logo._key}>
              {logo.link ? (
                <Link link={logo.link}>
                  <Logo logo={logo} />
                </Link>
              ) : (
                <Logo logo={logo} />
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
