import cx from 'classnames'
import Link from 'components/Link'
import Overline from 'components/Overline'
import Image from 'components/Image'
import useMedia from 'use-media'
import { screens } from 'lib/constants'
import Reverse from 'components/Reverse'
import getColorStyle from 'lib/getColorStyle'


export default function PressIconModule({ module, settings }) {
  const isScreenLarge = useMedia({ minWidth: screens.l })
  const Logo = ({ logo }) => {
    if (!logo?.svg) return null;
    const { width, height, aspectRatio } = logo.svg.asset.metadata.dimensions
    const renderedHeight = isScreenLarge ? 55 : 40
    const renderedWidth = width < height ? (isScreenLarge ? 100 : 80) : renderedHeight * aspectRatio
    return (
      <div className='flex h-fit self-center' 
        style={{
          width: `${renderedWidth}px`,
          height: `${width < height ? renderedWidth * aspectRatio : renderedHeight}px`,
          aspectRatio
        }}
      >
        {logo.link ? (
          <Link link={logo.link}>
            <Image alt={logo.title} placeholder={false} image={logo.svg} />
          </Link>
        ) : (
          <Image className='self-center' alt={logo.title} placeholder={false} image={logo.svg} />
        )}                      
      </div>
    )
  }

  return (
    <div
      className={cx('relative text-center py-28 l:py-75 px-32 l:px-88', {
        // 'mt-50 l:mt-80': settings.hasMarginTop,
        'mb-25 l:mb-45': settings.hasMarginBottom,
      })}
      style={{
        background: getColorStyle(module.backgroundColor) ?? '#f5f9ff'
      }}
    >
      { !!module?.overline?.trim() && <Overline className=''>{module.overline}</Overline>}

      <div className="">
        <ul className={"flex flex-wrap l:flex-nowrap w-full mx-auto gap-y-35 "}>
            {module.logos.map((logo) => (
              <li key={logo._key} className="w-1/2">
                <div className='flex flex-col h-full w-full'>
                  <Reverse if={logo.isTextFirst}>
                    <div className="h-1/2 flex self-center"  >
                      <Logo logo={logo} />
                    </div>    
                    <div className='h-1/2 mx-auto font-medium text-16 l:text-20' style={{maxWidth: '190px'}}>
                      {logo.title}
                    </div>     
                  </Reverse>
                </div>

              </li>
            ))}
          </ul>
      </div>
    </div>
  )
}
